import request from '@/found/utils/request';
import Storage from '@/found/utils/storage';
import Form, { formCreate } from '@/found/components/form';
import TableSelect from '../components/tableSelect.vue';

formCreate.component('TableSelect', TableSelect);
export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      formParentCode: 'CRM20230313000000002',
      formFunctionCode: 'external_employees_dealer_changes',
      buttonText: {
        submit: '保存',
      },
    };
  },

  methods: {
    // 设置rule
    setRule(v) {
      const item = v;
      if (item.field === 'ext13') {
        const paramsObj = {
          functionCode: 'dealer_list',
          data: [],
          selectionList: [],
          idKey: 'customerCode',
          noReset: true,
          paramData: { enableStatus: '009', actApproveStatus: 3 },
        };
        item.props = {
          ...item.props,
          paramsObj,
          placeholder: '所属经销商',
        };
      }
      return item;
    },
    // 表单渲染完成后调用
    async formComplete() {
      const ext13 = this.getRule('ext13');
      ext13.on.change = (val) => {
        this.setValue({
          ext13: val.customerName,
          ext12: val.customerCode,
        });
      };
    },

    // 表单提交
    submit() {
      const formData = this.getFormData();
      if (formData) {
        const url = '/sfa/sfaMdmUserController/updateCust';
        formData.id = this.formConfig.row.id;
        formData.userName = this.formConfig.row.userName;
        const params = { ...formData };
        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
  },
};
