import request from '@/found/utils/request';
import Form, { formCreate } from '@/found/components/form';

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      formParentCode: 'CRM20230313000000002',
      formFunctionCode: 'external_employees_ position_change',
      buttonText: {
        submit: '保存',
      },
    };
  },

  methods: {
    // 设置rule
    setRule(item) {
      const v = item;
      if (v.field === 'userName') {
        v.restful = '/mdm/mdmUserExtController/sfaFindUserParentPositionSelectList';
        v.restfulParams = { enableStatus: '009' };
        v.props = {
          ...v.props,
          filterable: true,
          remote: true,
          remoteParams: 'unionName',
        };
        v.optionsKey = {
          label: 'unionName',
          value: 'userName',
          parentCode: 'positionCode',
          parentName: 'positionName',
          orgCode: 'orgCode',
          fullName: 'fullName',
        };
        v.on = {
          ...v.on,
          getItem: (val) => {
            this.setValue({
              fullName: val.fullName,
              unionName: val.unionName,
              userName: val.value,
              parentCode: val.positionCode,
              parentName: val.positionName,
              orgCode: val.orgCode,
            });
          },
        };
      }
      if (v.field === 'positionLevelCode') {
        v.restful = '/mdm/mdmPositionExtController/sfaSelectList';
        v.restfulParams = { enableStatus: '009' };
        v.props = {
          ...v.props,
          filterable: true,
          remote: true,
          remoteParams: 'positionLevelName',
        };
        v.optionsKey = {
          label: 'positionLevelName',
          value: 'positionLevelCode',
        };
        v.on = {
          ...v.on,
          getItem: (val) => {
            this.setValue({ positionLevelName: val.positionLevelName, positionLevelCode: val.value });
          },
        };
      }
      return v;
    },
    // 表单渲染完成后回调
    formComplete() {

    },
    // 表单提交
    submit() {
      const formData = this.getFormData();
      formData.id = this.formConfig.row.id;
      formData.positionCode = this.formConfig.row.positionCode;
      formData.positionName = this.formConfig.row.positionName;
      console.log(formData, 'pform');
      if (formData) {
        const url = '/sfa/sfaMdmUserController/positionUpdate';
        const params = { ...formData };
        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
  },
};
