import request from '../../../../../../utils/request';
import BasePage from '../../../../../../components/table_page';
import { ProcessLog } from '../../../../../../modules';
import Form from '../form';
import RenewalForm from '../renewal_form';
import PositionChangeForm from '../position_change_form';
import DealerChangesForm from '../dealer_changes_form';
import ChangesForm from '../change_form';

export default {
  name: 'external_employees',
  extends: BasePage,
  data() {
    return {
      params: {

      },
    };
  },
  components: {
    Form,
    ProcessLog,
    RenewalForm,
    PositionChangeForm,
    DealerChangesForm,
    ChangesForm,
  },
  created() {
    this.getConfigList('external_employees_list');
  },
  methods: {
    // 控制按钮显示
    clickVisible({ row, val: { code } }) {
      // 待提交 SUBMIT
      // 审批通过 PASS
      // 审批中 APPROVAL
      // 活动关闭 CLOSE
      // 流程追回 RECOVER
      // 驳回 REJECT
      const { checkStatus, sfaCheckType } = row;
      if (code === 'position_change') {
        if (sfaCheckType === 'add' && checkStatus === 'PASS') {
          return true;
        }
        if ((sfaCheckType === 'modify') && (checkStatus === 'PASS' || checkStatus === 'SUBMIT' || checkStatus === 'REJECT')) {
          return true;
        }
        return false;
      }
      if (code === 'renewal') {
        if (sfaCheckType === 'add' && checkStatus === 'PASS') {
          return true;
        }
        if ((sfaCheckType === 'modify') && (checkStatus === 'PASS' || checkStatus === 'SUBMIT' || checkStatus === 'REJECT')) {
          return true;
        }
        return false;
      }
      if (code === 'dealer_changes') {
        if (sfaCheckType === 'add' && checkStatus === 'PASS') {
          return true;
        }
        if ((sfaCheckType === 'modify') && (checkStatus === 'PASS' || checkStatus === 'SUBMIT' || checkStatus === 'REJECT')) {
          return true;
        }
        return false;
      }
      if (code === 'change') {
        if (sfaCheckType === 'add' && checkStatus === 'PASS') {
          return true;
        }
        if (sfaCheckType === 'modify' && (checkStatus === 'PASS' || checkStatus === 'SUBMIT' || checkStatus === 'REJECT')) {
          return true;
        }
        return false;
      }
      if (code === 'edit') {
        if ((checkStatus === 'SUBMIT' || checkStatus === 'REJECT') && sfaCheckType === 'add') {
          return true;
        }
        return false;
      }
      return true;
    },
    modalClick({ val, row }) {
      const { code } = val;
      if (code === 'add' || code === 'edit' || code === 'view') {
        this.formName = 'Form';
        this.formConfig = {
          ...this.formConfig,
          code,
          row,
        };
        this.modalConfig.title = val.name || '查看';
        this.openFull();
      } else if (code === 'renewal') {
        this.formName = 'RenewalForm';
        this.modalConfig.width = '40%';
        this.formConfig = {
          ...this.formConfig,
          code,
          row,
        };
        this.modalConfig.title = '续期';
        this.openModal();
      } else if (code === 'position_change') {
        this.formName = 'PositionChangeForm';
        this.modalConfig.width = '40%';
        this.formConfig = {
          ...this.formConfig,
          code,
          row,
        };
        this.modalConfig.title = '职位变动';
        this.openModal();
      } else if (code === 'dealer_changes') {
        this.formName = 'DealerChangesForm';
        this.modalConfig.width = '40%';
        this.formConfig = {
          ...this.formConfig,
          code,
          row,
        };
        this.modalConfig.title = '经销商变更';
        this.openModal();
      } else if (code === 'change') {
        this.formName = 'ChangesForm';
        this.modalConfig.width = '40%';
        this.formConfig = {
          ...this.formConfig,
          code,
          row,
        };
        this.modalConfig.title = '变更';
        this.openModal();
      } else if (code === 'close_row') {
        this.$confirm('是否确认关闭数据?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            request.post('/sfa/sfaMdmUserController/delete', [row.id]).then((res) => {
              if (res.success) {
                this.$message({
                  type: 'success',
                  message: '操作成功',
                });
                this.getList();
              }
            });
          });
      }
    },
    // 保存回调
    onSubmit(params) {
      const configParams = {
        ...params,
        code: 'approve',
      };

      this.$emit('approve', configParams);
    },
  },
};
