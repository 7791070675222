import request from '@/found/utils/request';
import Form, { formCreate } from '@/found/components/form';

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      formParentCode: 'CRM20230313000000002',
      formFunctionCode: 'external_employees_ renewal',
      buttonText: {
        submit: '保存',
      },
    };
  },
  methods: {
    // 设置rule
    setRule(item) {
      const v = item;
      if (v.field === 'startTime') {
        v.props = {
          ...v.props,
          type: 'datetime',
          valueFormat: 'yyyy-MM-dd HH:mm:ss',
          defaultTime: '00:00:00',
        };
      }
      if (v.field === 'endTime') {
        v.props = {
          ...v.props,
          type: 'datetime',
          valueFormat: 'yyyy-MM-dd HH:mm:ss',
          defaultTime: '23:59:59',
        };
      }
      return v;
    },
    // 表单渲染完成后回调
    formComplete() {

    },
    // 表单提交
    submit() {
      const formData = this.getFormData();
      formData.id = this.formConfig.row.id;
      console.log(formData, 'rform');
      if (formData) {
        const url = '/sfa/sfaMdmUserController/updateTime';
        const params = { ...formData };
        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
  },
};
